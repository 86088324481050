import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export type PageTabType = {
  name: string,
  label: string,
  path: string,
  isDisabled: boolean
}

@Component({
    selector: 'page-tabs',
    template: `
    <nav class="tabs">
      <a *ngFor="let tab of tabs"
         [class.active]="tab.name === activeTab"
         [class.disabled]="tab.isDisabled"
         (click)="!tab.isDisabled && navigate(tab.path, tab.name)">
        {{ tab.label }}
      </a>
    </nav>
  `,
    styles: [
        `
    .tabs {
      display: flex;
      border-bottom: 2px solid #ddd;
    }
    .tabs a {
      padding: 10px 15px;
      cursor: pointer;
      text-decoration: none;
      color: black;
    }
    .tabs a.active {
      font-weight: bold;
      color: #e91e63;
      border-bottom: 2px solid #e91e63;
    }
    .tabs a.disabled {
      color: grey;
      cursor: not-allowed;
      opacity: 0.5;
    }
    `
    ],
    standalone: false
})
export class PageTabsComponent implements OnInit {

  @Input() tabs: PageTabType[] = [];

  activeTab: string = undefined;

  constructor(private router: Router) { }
  ngOnInit(): void {
    this.navigate(this.tabs[0].path, this.tabs[0].name)

  }

  navigate(path: string, name: string) {
    const currentUrl = this.router.url.split('?')[0]; // Mantiene la base senza query params
    const firstUrlPart = currentUrl.slice(0, currentUrl.lastIndexOf('/'));
    const lastUrlPart = currentUrl.slice(currentUrl.lastIndexOf('/') + 1);
    if (this.tabs.map(elm => elm.path).includes(lastUrlPart)) {
      this.router.navigateByUrl(`${firstUrlPart}/${path}`);
      this.activeTab = name;

    } else {
      this.router.navigateByUrl(`${currentUrl}/${path}`);
      this.activeTab = name;
    }
    //   this.router.navigateByUrl(`${currentUrl}/${path}`);
    //  this.activeTab = name;
  }
}
