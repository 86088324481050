import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'getFileFormatPipe',
    standalone: false
})
export class GetFileFormatPipe implements PipeTransform {
    transform(value: string, ...args: any[]) {
        return value.slice(value.lastIndexOf('.'))
    }
}