import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'calculateRatingsPercentagePipe',
    standalone: false
})
export class CalculateRatingsPercentagePipe implements PipeTransform {
    transform(value: number, ...args: any[]) {
        if (value === 0) {
            return 0;
        }
        return ((value * 100) / 5);
    }
}