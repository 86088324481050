import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'dateFormatPipe',
    standalone: false
})
export class DateFormatPipe implements PipeTransform {
    transform(value: Date | string, ...args: any[]) {
        if (value)
            return new Date(value).toLocaleString('en-UK')
        else
            return '----'
    }
}