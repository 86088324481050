import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'resolveFullnamePipe',
    standalone: false
})
export class ResolveFullnamePipe implements PipeTransform {
    transform(value: string, ...args: [{ index: number }]) {
        return value.split('|')[args[0].index];
    }
}