<ng-container *ngIf="{
    isLoading: isLoading$ | async
} as vm">
    <div class="loading-overlay" *ngIf="vm.isLoading">
        <albi-loader></albi-loader>
    </div>
    <div class="border-card d-grid gap-24">
        <div class="d-flex flex-column align-center j-center gap-12">
            <img src="assets/glass.png" alt="" height="40px">
            <div class="text-lg font-bold">Albi-{{stage}} Backoffice</div>
            <div class="text-sm font-regular">Effettua il login con l'account ADMIN Albicchiere per utilizzare il
                Backoffice.</div>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-group">
            <div class="form-input-wrapper col-span-2">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Email</div>
                </div>
                <albi-input [control]="loginForm.controls.username"></albi-input>
            </div>
            <div class="form-input-wrapper col-span-2">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Password</div>
                </div>
                <albi-input [control]="loginForm.controls.password" type="password"></albi-input>
            </div>
            <div class="col-span-2 w-full">
                <albi-button type="submit" (onClick)="onSubmit()" label="Login" [size]="'fluid'"></albi-button>
            </div>
            <div class="d-flex j-center col-span-2">
                <img src="assets/albicchiere.png" alt="" height="32px">
            </div>
        </form>

    </div>

</ng-container>