<ng-container *ngIf="{
    countrySuggestion:countrySuggestion$ | async
} as vm">
    <div class="form-group">
        <div class="form-input-label col-span-2" *ngIf="formTitle || formDescription">
            <div class="text-lg font-bold" *ngIf="formTitle">{{formTitle}}</div>
            <div class="text-sm font-regular" *ngIf="formDescription">{{formDescription}}</div>
        </div>
        <div class="form-input-wrapper col-span-2">
            <div class="form-input-label">
                <div class="text-sm font-bold">Nome della location *</div>
                <div class="text-sm font-regular">Inserisci un nome per identificare l'indirizzo.</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyAddressForm.controls.name"
                autocomplete="address-level2"></albi-input>
            <label class="d-flex align-center cliccable font-medium text-xs">
                <albi-checkbox [control]="companyAddressForm.controls.isShipping"></albi-checkbox>
                Salva come indirizzo di spedizione
            </label>
            <label class="d-flex align-center cliccable font-medium text-xs">
                <albi-checkbox [control]="companyAddressForm.controls.isBilling"></albi-checkbox>
                Salva come indirizzo di fatturazione
            </label>
        </div>
        <div class="form-input-label col-span-2">
            <div class="text-sm font-bold">Indirizzo *</div>
            <div class="text-sm font-regular">Inserisci l'indirizzo della tua azienda</div>
        </div>
        <div class="form-input-wrapper">
            <albi-input [fullWidth]="true" [control]="companyAddressForm.controls.address" autocomplete="street-address"
                [placeholder]="'Via e numero civico'"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <albi-input [fullWidth]="true" [control]="companyAddressForm.controls.city" autocomplete="address-level2"
                [placeholder]="'Comune'"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <albi-input [fullWidth]="true" [control]="companyAddressForm.controls.state" autocomplete="address-level1"
                [placeholder]="'Provincia'"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <albi-autocomplete dataKey="name" [placeholder]="'Paese'" [isFluidElement]="true" autocomplete="country"
                [suggestions]="vm.countrySuggestion" (completeMethod)="changeCountrySuggestion($event)"
                [control]="companyAddressForm.controls.country">
                <ng-template #itemTemplate let-suggestion>
                    {{suggestion?.name}}
                </ng-template>
            </albi-autocomplete>
        </div>
        <div class="form-input-wrapper">
            <albi-input [fullWidth]="true" [placeholder]="'Codice postale'"
                [control]="companyAddressForm.controls.postalCode" autocomplete="postal-code"></albi-input>
            <!-- placeholder="Codice postale"></albi-input> -->
        </div>
    </div>
</ng-container>