import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';

export type ImageCropperResult = {
    blob: Blob,
    imageUrl: string,
}

@Component({
    selector: 'app-image-crop',
    templateUrl: 'image-crop.component.html',
    styleUrls: ['image-crop.component.scss'],
    standalone: false
})
export class ImageCropComponent implements OnInit, OnChanges {

    @Input() imageFile: File;
    @Input() width: number;
    @Input() height: number;

    @Output() imageResult = new EventEmitter<ImageCropperResult>();

    imageBlob$ = new BehaviorSubject<Blob>(null)

    mantainAspetcRatio$ = new BehaviorSubject<boolean>(false);

    constructor() { }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['imageUrl']?.currentValue) {
            this.imageFile = changes['imageUrl']?.currentValue
        }
    }

    ngOnInit(): void {
    }

    imageCropped(ev: ImageCroppedEvent) {
        const { blob, objectUrl } = ev;
        if (blob && objectUrl) {
            this.imageResult.emit({
                blob,
                imageUrl: objectUrl
            });
        }

        this.mantainAspetcRatio$.next(Boolean(this.height) && Boolean(this.width))
    }
}
