

export function getStatusSeverity(statusValue: String) {
    switch (statusValue) {
        case 'active': return 'success';
        case 'pending': return 'warning';
        case 'inactive': return 'danger';
        default: return 'danger'
    }
}

export function resolveUrlQueryParameters(endpoint: string, limit: number, page: number, filters: { [key: string]: string }) {
    const filtersString = Object.keys(filters).map(filterKey => {
        return `&${filterKey}=${filters[filterKey]}`
    }).join('')
    return encodeURI(`${endpoint}?${limit ? `limit=${limit}` : 'limit=10'}${page ? `&page=${page}` : ''}${filtersString}`)
}

/**
 * function that resolve an object containing various translations trying to get the locale received in input
 * or resolves to the default fallback language
 * @param translatedField the field that have varioustranslations (ex:{'it':'ciao','en':'hello',...})
 * @param locale define the desired response language 
 * @param defaultFallback language to fallback if the desired one is not included in the translated field, default to en
 */
export function resolveDBLocaleFields(translatedField: { [key: string]: string }, locale: string, defaultFallback: string = 'en') {
    return translatedField[locale] || translatedField[defaultFallback];
}
