import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";


export type HttpCallOptions = {
    extraHeaders?: { key: string, value: string }[],
}

@Injectable()
export class BackendService {
    private readonly _baseUrl = environment.apiUrl

    constructor(private _http: HttpClient, private _authService: AuthService,) { }

    public get<T>(endpoint: string, opts?: HttpCallOptions): Observable<T> {
        const headers = this.calculateHeaders(opts);

        return this._http.get<T>(`${this._baseUrl}/${endpoint}`, {
            headers: headers
        });
    }

    public getFullResponse<T>(endpoint: string, opts?: HttpCallOptions): Observable<HttpResponse<T>> {
        const headers = this.calculateHeaders(opts);

        return this._http.get<T>(`${this._baseUrl}/${endpoint}`, {
            headers: headers,
            observe: 'response',

        });
    }

    public post<T>(endpoint: string, body: any, opts?: HttpCallOptions) {
        const headers = this.calculateHeaders(opts);
        return this._http.post<T>(`${this._baseUrl}/${endpoint}`, body, {
            headers: headers
        });

    }
    public patch<T>(endpoint: string, etag: string, body: any, opts?: HttpCallOptions) {
        const headers = this.calculateHeaders(opts, etag);
        return this._http.patch<T>(`${this._baseUrl}/${endpoint}`, body, {
            headers: headers
        });

    }


    private calculateHeaders(opts?: HttpCallOptions, etag?: string) {

        const headers: HttpHeaders | {
            [header: string]: string | string[];
        } = {
            "Authorization": `Bearer ${this._authService.getSessionInfo().accessToken}`,
        }
        if (opts?.extraHeaders) {
            opts.extraHeaders.forEach(elm => headers[elm.key] = elm.value)
        }
        if (etag) {
            headers["if-match"] = etag;
        }
        return headers


    }
}