<ng-container *ngIf="{
    wineRegionOptions: wineRegionOptions$ | async,
    wineCountryOptions: wineCountryOptions$ | async,
    wineGrapesSuggestions: wineGrapesSuggestions$ | async,
    bottleImage:bottleImage$ | async,
    labelImage:labelImage$ | async,
    wineFormValue:wineCreateForm.valueChanges | async,
} as vm">
    <div class="border-card" *ngIf="!(notEditableMode && selectedWineId);else selectedWineTemplate">

        <div class="form-group">
            <div class="col-span-2 d-grid gap-12">
                <div class="text-lg font-bold">Vino</div>
                <div class="d-flex align-center gap-12 text-sm font-regular">
                    <div class="text-sm font-regular">{{headerParagraphText}}</div>
                    <albi-switch *ngIf="switchEditableMode" [control]="switchEditableModeControl"></albi-switch>
                </div>
            </div>

            <div class="divider col-span-2"></div>
            <!-- wine name -->
            <div class="form-input-wrapper col-span-2">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Nome</div>
                    <div class="text-sm font-regular">Nome del vino</div>
                </div>
                <albi-input [fullWidth]="true" [control]="wineCreateForm.controls.name"></albi-input>
            </div>

            <!-- wine color -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Colore</div>
                    <div class="text-sm font-regular">Colore del vino</div>
                </div>
                <albi-multiple-choice [options]="wineColorOptions" [fullWidth]="true"
                    [formControl]="wineCreateForm.controls.wineColor"></albi-multiple-choice>
            </div>

            <!-- wine types -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Wine types</div>
                    <div class="text-sm font-regular">Wine types del vino</div>
                </div>
                <albi-radio-button [values]="wineTypeOptions" radioGroup="wineTypes" [externalStroke]="false"
                    [isFluid]="true" [formControl]="wineCreateForm.controls.wineTypes"></albi-radio-button>
            </div>

            <!-- wine region -->
            <div class="form-input-wrapper col-span-2">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Regione</div>
                    <div class="text-sm font-regular">Regione vinicola del vino</div>
                </div>

                <div class="d-grid grid-cols-2 gap-12">
                    <albi-autocomplete (completeMethod)="completeRegionSuggestions($event)"
                        [suggestions]="vm.wineRegionOptions" [isFluidElement]="true" dataKey="name"
                        [control]="wineCreateForm.controls.region" (onSelect)="onRegionSelect($event)"
                        placeholder="Chianti">
                        <ng-template #itemTemplate let-suggestion>
                            {{suggestion?.name}}
                        </ng-template>
                    </albi-autocomplete>
                    <albi-autocomplete (completeMethod)="completeCountrySuggestions($event)"
                        [suggestions]="vm.wineCountryOptions" [isFluidElement]="true" dataKey="countryName"
                        [control]="wineCreateForm.controls.country" (onSelect)="onCountrySelect($event)"
                        placeholder="Italia">
                        <ng-template #itemTemplate let-suggestion>
                            {{suggestion?.countryName}}
                        </ng-template>
                    </albi-autocomplete>
                </div>
            </div>

            <!-- wine grapes -->
            <div class="form-input-wrapper col-span-2">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Uve</div>
                    <div class="text-sm font-regular">Uve presenti nel vino</div>
                </div>
                <albi-chips [formControl]="wineCreateForm.controls.grapes" [suggestions]="vm.wineGrapesSuggestions"
                    [forceSelection]="true" (completeMethod)="completeGrapeSuggestions($event)"></albi-chips>
            </div>
        </div>
    </div>


    <ng-template #selectedWineTemplate>
        <div class="border-card">

            <div class="form-group">

                <!-- wine name -->
                <div class="form-input-wrapper col-span-2">
                    <div class="form-input-label">
                        <div class="text-sm font-bold">Nome</div>
                        <div class="text-sm font-regular">
                            {{vm.wineFormValue?.name}}</div>
                    </div>
                </div>

                <!-- wine color -->
                <div class="form-input-wrapper">
                    <div class="form-input-label">
                        <div class="text-sm font-bold">Colore</div>
                        <div class="text-sm font-regular">
                            {{vm.wineFormValue?.wineColor?.key ==='red'?
                            ('Rosso'):
                            vm.wineFormValue?.wineColor?.key ==='white'?
                            ('Bianco'):
                            vm.wineFormValue?.wineColor?.key ==='rose'?
                            ('Rosè'):
                            ''
                            }} {{vm.wineFormValue?.wineTypes?.length>0?
                            (
                            ' - '+
                            (
                            vm.wineFormValue.wineTypes.includes('sweet')?
                            ('Dolce'):
                            vm.wineFormValue.wineTypes.includes('sparkling')?
                            ('Frizzante'):
                            vm.wineFormValue.wineTypes.includes('fortified')?
                            ('Fortificato'):
                            ''
                            )
                            ):''}}
                        </div>
                    </div>

                </div>

                <!-- wine region -->
                <div class="form-input-wrapper">
                    <div class="form-input-label">
                        <div class="text-sm font-bold">Regione</div>
                        <div class="text-sm font-regular">
                            {{vm.wineFormValue?.region?.name}}</div>
                    </div>
                </div>

                <!-- wine grapes -->
                <div class="form-input-wrapper col-span-2">
                    <div class="form-input-label">
                        <div class="text-sm font-bold">Uve</div>
                        <div class="text-sm font-regular">
                            {{vm.wineFormValue?.grapes | resolveChipsArrayPipe}}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>