import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as clm from "country-locale-map";


@Pipe({
    name: 'resolveCountryCodePipe',
    standalone: false
})
export class ResolveCountryCodePipe implements PipeTransform {

    constructor(private _translateService: TranslateService) { }

    transform(value: string, ...args: any[]) {
        const translateCountryName = new Intl.DisplayNames([this._translateService.currentLang], { type: 'region' })
        if (value) {
            switch (value.length) {
                case 3:
                    return translateCountryName.of(clm.getAlpha2ByAlpha3(value.toUpperCase()))
                case 2:
                    return translateCountryName.of(value.toUpperCase())
                default:
                    return value
            }
        } else
            return '---'
    }
}