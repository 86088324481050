import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "src/services/auth.service";

@Component({
    selector: 'app-page-layout',
    templateUrl: 'app-page-layout.component.html',
    styleUrls: ['app-page-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppPageLayoutComponent {

    menuItems = [
        {
            name: 'Vini', path: 'wines/wines', icon: 'wine.grapeVariety', subsections: [
                { name: 'Vini', path: 'wines/wines' },
                { name: 'Annate', path: 'wines/vintages' },
                { name: 'User Vintage', path: 'wines/user-vintages' },
            ]
        },
        {
            name: 'Aziende', path: 'companies', icon: 'legal.businessAccount', subsections: [
                { name: 'Aziende', path: 'companies' },
                { name: 'Aziende Pending', path: 'document-pending' }
            ]
        },
        {
            name: 'Dispensers', path: 'dispensers', icon: 'menu.profile', subsections: [
                { name: 'Dispensers', path: 'dispensers' }
            ]
        },
        {
            name: 'QR Code', path: 'qrcode', icon: 'stats.statsLabel', subsections: [
                { name: 'QR Code', path: 'qrcode' }
            ]
        },
        {
            name: 'Utenti', path: 'users', icon: 'menu.profile', subsections: [
                { name: 'Utenti', path: 'users' }
            ]
        }
    ];

    stage = environment.stage;


    constructor(
        private cdr: ChangeDetectorRef,
        private _authService: AuthService,
    ) { }

    toggleDetails(details: HTMLDetailsElement) {
        this.cdr.detectChanges(); // Forza il rilevamento dei cambiamenti per aggiornare l'icona
    }


    public logout() {
        this._authService.logout();
    }
}