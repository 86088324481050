import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'getStatusLabelPipe',
    standalone: false
})
export class GetStatusLabelPipe implements PipeTransform {
    transform(value: string, ...args: any[]) {
        const stateOptions: { value: string, label: string }[] = args[0].options
        return stateOptions.find(el => el.value === value).label;
    }
}