import { Pipe, PipeTransform } from "@angular/core";
import { AlbiChipType } from "albi-ui/dist/albi-ui-library";

@Pipe({
    name: 'resolveChipsArrayPipe',
    standalone: false
})
export class ResolveChipsArrayPipe implements PipeTransform {

    constructor(
    ) { }

    transform(value: AlbiChipType[], ...args: any[]) {
        if (!value) {
            return ''
        }
        return value.map(elm => elm.text).join(', ')
    }
}