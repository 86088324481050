<ng-container *ngIf="{
    companyLogoSrc:companyLogoSrc$ | async,
} as vm">
    <div class="form-group">
        <div class="form-input-label col-span-2" *ngIf="formTitle || formDescription">
            <div *ngIf="formTitle" class="text-lg font-bold">{{formTitle}}</div>
            <div *ngIf="formDescription" class="text-sm font-regular">{{formDescription}}</div>
        </div>
        <div class="form-input-wrapper col-span-2">
            <div class="form-input-label">
                <div class="text-sm font-bold">Tipologia di azienda</div>
                <div class="text-sm font-regular">Seleziona la tipologia della tua azienda. Seleziona “cantina” solo se
                    sei produttore diretto di
                    vino. Reseller, importatori, distributori o utilizzatori dei servizi Albicchiere devono
                    selezionare l’opzione “professional”.</div>
            </div>
            <albi-radio-button [isFluid]="true" radioGroup="companyType" [values]="[
                {
                    key:'winery',
                    name:'Winery',
                    description:'Seleziona Winery se la tua azienda è una cantina produttrice.'
                },
                {
                    key:'professional',
                    name:'Professional',
                    description:'Seleziona Professional se la tua azienda eroga servizi.'
                },
            ]" [formControl]="companyCreateForm.controls.type"></albi-radio-button>
        </div>
        <div class="form-input-wrapper col-span-2">
            <div class="text-sm font-bold">Logo dell'azienda</div>
            <div class="d-flex align-center gap-3">
                <img [src]="vm.companyLogoSrc || companyImage" onerror="this.src='assets/company-avatar.png'"
                    height="80px" class="avatar-image medium contain">
                <div class="text-sm font-regular">Carica il logo del tuo brand.</div>
                <albi-button buttonVariant="textOnly" iconName="arrow.plus"
                    (onClick)="loadCompanyImage()"></albi-button>
            </div>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">Nome cantina *</div>
                <div class="text-sm font-regular">Questo è il nome con cui la tua cantina è conosciuta nel mondo e
                    comparirà nelle tue
                    etichette</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.name"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">Email *</div>
                <div class="text-sm font-regular">Inserisci l'indirizzo email aziendale di riferimento</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.email"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">Ragione sociale *</div>
                <div class="text-sm font-regular">Inserisci la ragione sociale della tua azienda. Questo nome verrà
                    utilizzato come produttore.</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.fullName"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">Partita iva *</div>
                <div class="text-sm font-regular">Inserisci la partita iva della tua azienda, completa di codice paese
                    (per es. IT00000000000)</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.vatNumber"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">Sito web</div>
                <div class="text-sm font-regular">Inserisci l’URL del tuo sito web</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.website"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">Telefono *</div>
                <div class="text-sm font-regular">Inserisci il numero di telefono della tua azienda.</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.phone"></albi-input>
        </div>
    </div>

</ng-container>