import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'sliderValueFormatPipe',
    standalone: false
})
export class SliderValueFormatPipe implements PipeTransform {
    transform(value: number[], ...args: any[]): string {
        if (value.length === 2) {
            return value[0] + '-' + value[1]
        } else if (value.length === 1) {
            return value[0].toString()
        } else
            return value.toString()
    }
}