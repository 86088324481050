<div class="container">
    <!-- Sidebar -->
    <div class="sidebar">
        <div class="sidebar-header">Backoffice {{stage}}</div>
        <nav>
            <ul>
                <li *ngFor="let section of menuItems" class="menu-item" routerLinkActive="selected">
                    <details #deds (toggle)="toggleDetails(deds)">
                        <summary>
                            <div class="d-flex align-center gap-12">
                                <albi-icon [name]="section.icon"></albi-icon>
                                <span>{{ section.name }}</span>
                            </div>
                            <albi-icon *ngIf="!deds.open" name="arrow.chevronDown"></albi-icon>
                            <albi-icon *ngIf="deds.open" name="arrow.chevronUp"></albi-icon>
                        </summary>
                        <ul>
                            <li class="menu-subitem" *ngFor="let sub of section.subsections"
                                routerLinkActive="selected">
                                <a [routerLink]="sub.path">{{ sub.name }}</a>
                            </li>
                        </ul>
                    </details>
                </li>
            </ul>
        </nav>
        <button class="logout" (click)="logout()">Logout</button>
    </div>

    <!-- Main Content -->
    <div class="page-wrapper">
        <div class="page-header">

        </div>
        <div class="dashboard-message-panel" id="dashboard-message-panel">
        </div>
        <div class="page-body">
            <div class="body-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="page-footer">

        </div>

    </div>
</div>