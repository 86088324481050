<ng-container *ngIf="{
    bottleImage:bottleImage$ | async,
    labelImage:labelImage$ | async,
    winePrimaryPairingsSuggestions:winePrimaryPairingsSuggestions$ | async,
    wineSecondaryPairingsSuggestions: wineSecondaryPairingsSuggestions$ | async,
    wineMoodsSuggestions: wineMoodsSuggestions$ | async,
    wineAllergensSuggestions:wineAllergensSuggestions$ | async,
    winePhilosophySuggestions: winePhilosophySuggestions$ | async,
    showVintageYearPicker:showVintageYearPicker$ | async,
    isYearDetailRequired:isYearDetailRequired$ | async,

    loadedLabelImageFile:loadedLabelImageFile$ | async,
    loadedBottleImageFile:loadedBottleImageFile$ | async,
} as vm">

    <div class="border-card">
        <div class="form-group">
            <div class="form-input-wrapper col-span-2 d-grid gap-12">
                <div class="text-lg font-bold">Annata</div>
                <div class="text-sm font-regular">Dati dell'annata</div>
            </div>

            <div class="divider col-span-2"></div>

            <div class="form-input-wrapper">
                <div class="d-grid gap-12">
                    <div class="form-input-label">
                        <div class="text-sm font-bold">Anno</div>
                        <div class="text-sm font-regular">Anno in cui è stato imbottigliato il vino</div>
                    </div>
                    <div class="d-grid gap-12">
                        <albi-radio-button [values]="vintageTypeOptions" radioGroup="vintageType" [isFluid]="true"
                            [formControl]="vintageCreateForm.controls.yearType"></albi-radio-button>
                        <albi-calendar *ngIf="vm.showVintageYearPicker" [formControl]="vintageCreateForm.controls.year"
                            variant="yearPicker" placeholder="seleziona l'annata" [fullWidth]="true"></albi-calendar>
                        <div *ngIf="vm.isYearDetailRequired" class="required-control-tex">Required *</div>
                        <albi-input *ngIf="vintageCreateForm.controls.yearType.value"
                            [control]="vintageCreateForm.controls.yearDetails" [fullWidth]="true"
                            [placeholder]="'Edizione speciale'"></albi-input>
                    </div>
                </div>
            </div>
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Immagini</div>
                    <div class="text-sm font-regular">Inserire le immagini dell annata</div>
                </div>
                <div class="image-load-container">
                    <div class="image-wrapper" [ngClass]="{
                            'image-size-error':vintageCreateForm.controls.labelImage.value?.sizeError
                        }">
                        <div class="img-title">Etichetta</div>
                        <div class="text-xs font-regular">La dimensione deve essere 480px x 640px</div>
                        <div class="relative">
                            <img [src]="vm.labelImage" onerror="this.src='assets/label-placeholder.png'" alt="">
                            <albi-icon *ngIf="vm.labelImage" class="remove-img-icon" name="arrow.close"
                                (onClick)="removeImage('label')"></albi-icon>
                        </div>
                        <albi-button [label]="vm.labelImage?('Cambia'):('Aggiungi')"
                            [iconName]="vm.labelImage?'arrow.repeat':'arrow.plus'" buttonVariant="textOnly"
                            (onClick)="handleAddLabel()"></albi-button>
                        <span class="text-danger" *ngIf="vintageCreateForm.controls.labelImage.value?.sizeError">Carica
                            l’immagine della etichetta e della bottiglia del tuo vino. Segui i formati o il rapporto
                            indicato. Le immagini caricate saranno visualizzate dai consumatori quindi ti suggeriamo di
                            seguire le indicazioni sotto.</span>
                    </div>
                    <div class="image-wrapper" [ngClass]="{
                        'image-size-error':vintageCreateForm.controls.bottleImage.value?.sizeError
                    }">
                        <div class="img-title">Bottiglia</div>
                        <div class="text-xs font-regular">L'immagine deve avere un altezza di 960px</div>
                        <div class="relative">
                            <img [src]="vm.bottleImage" onerror="this.src='assets/bottle-placeholder.png'" alt="">
                            <albi-icon *ngIf="vm.bottleImage" class="remove-img-icon" name="arrow.close"
                                (onClick)="removeImage('bottle')"></albi-icon>
                        </div>
                        <albi-button [label]="vm.bottleImage?('Cambia'):('Aggiungi')"
                            [iconName]="vm.bottleImage?'arrow.repeat':'arrow.plus'" buttonVariant="textOnly"
                            (onClick)="handleAddBottle()"></albi-button>
                        <span class="text-danger" *ngIf="vintageCreateForm.controls.bottleImage.value?.sizeError">Carica
                            l’immagine della etichetta e della bottiglia del tuo vino. Segui i formati o il rapporto
                            indicato. Le immagini caricate saranno visualizzate dai consumatori quindi ti suggeriamo di
                            seguire le indicazioni sotto.</span>
                    </div>
                </div>
            </div>

            <!-- acohol -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Volume alcolico *</div>
                    <div class="text-sm font-regular">Inserisci il corretto volume alcolico per automatizzare il calcolo
                        dei valori energetici.</div>
                </div>
                <albi-input [control]="vintageCreateForm.controls.alcohol" [fullWidth]="true" type="number"
                    [minValue]="0" [maxValue]="100"></albi-input>

            </div>

            <!-- service temperature -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Temperatura di servizio *</div>
                    <div class="text-sm font-regular">Indica la temperatura alla quale dovrebbe essere servito il tuo
                        vino. Garantisci
                        un’esperienza di degustazione perfetta ai tuoi consumatori.</div>
                </div>
                <albi-input [control]="vintageCreateForm.controls.temperature" [fullWidth]="true" type="number"
                    [minValue]="0" [maxValue]="30"></albi-input>
            </div>

            <!-- pairings perfect -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Abbinamenti perfetti</div>
                    <div class="text-sm font-regular">Inserisci fino a 3 food, che ritieni perfetti per accompagnare il
                        tuo vino. Compariranno tra i
                        suggeriti.
                    </div>
                </div>
                <albi-chips [formControl]="vintageCreateForm.controls.primaryFoods"
                    (completeMethod)="completePairingsSuggestions($event,'primary')"
                    [suggestions]="vm.winePrimaryPairingsSuggestions" [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completePairingsSuggestions(vm.winePrimaryPairingsSuggestions?.length? '':$event,'primary',vm.winePrimaryPairingsSuggestions?.length?false: true)"
                    [maxNumberOfChips]="3"></albi-chips>
            </div>

            <!-- pairings good -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Abbinamenti ottimi</div>
                    <div>Inserisci fino a 6 food che si abbinano bene al tuo vino ma non compariranno tra i suggeriti.`
                    </div>
                </div>
                <albi-chips [formControl]="vintageCreateForm.controls.secondaryFoods"
                    (completeMethod)="completePairingsSuggestions($event,'secondary')"
                    [suggestions]="vm.wineSecondaryPairingsSuggestions" [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completePairingsSuggestions(vm.wineSecondaryPairingsSuggestions?.length? '':$event,'secondary',vm.wineSecondaryPairingsSuggestions?.length?false: true)"
                    [maxNumberOfChips]="6"></albi-chips>
            </div>

            <div class="divider col-span-2"></div>

            <!-- mood -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Mood</div>
                    <div class="text-sm font-regular">Seleziona gli stati d’animo ideali per degustare il tuo vino.
                    </div>
                </div>
                <albi-chips class="text-sm font-regular" [formControl]="vintageCreateForm.controls.moods"
                    (completeMethod)="completeMoodsSuggestions($event)" [suggestions]="vm.wineMoodsSuggestions"
                    [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completeMoodsSuggestions(vm.wineMoodsSuggestions?.length? '':$event,vm.wineMoodsSuggestions?.length?false: true)"></albi-chips>
            </div>

            <!-- philosophy -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Filosofia</div>
                    <div class="text-sm font-regular">Indica con quale filosofia produttiva è stato realizzato la tua
                        annata.</div>
                </div>
                <albi-chips class="text-sm font-regular" [formControl]="vintageCreateForm.controls.philosophy"
                    (completeMethod)="completePhilosophySuggestions($event)"
                    [suggestions]="vm.winePhilosophySuggestions" [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completePhilosophySuggestions(vm.winePhilosophySuggestions?.length? '':$event,vm.winePhilosophySuggestions?.length?false: true)"></albi-chips>
            </div>

            <!-- allergens -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Allergeni</div>
                    <div class="text-sm font-regular">Seleziona gli allergeni che fanno parte dell’annata</div>
                </div>
                <albi-chips class="text-sm font-regular" [formControl]="vintageCreateForm.controls.allergens"
                    (completeMethod)="completeAllergensSuggestions($event)" [suggestions]="vm.wineAllergensSuggestions"
                    [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completeAllergensSuggestions(vm.wineAllergensSuggestions?.length? '':$event,vm.wineAllergensSuggestions?.length?false: true)"></albi-chips>
            </div>

            <!-- awards -->
            <div class="form-input-wrapper col-span-2">
                <div class="form-input-label">
                    <div class="text-sm font-bold">Riconoscimenti</div>
                    <div class="text-sm font-regular">Inserisci i riconoscimenti ricevuti da questa annata e premi invio
                        per salvare il testo digitato.</div>
                </div>
                <albi-chips class="text-sm font-regular" textIcon="🏆" class="h-full"
                    [formControl]="vintageCreateForm.controls.awards"></albi-chips>
            </div>
        </div>
    </div>



    <!--  ERROR label size -->
    <div class="crop-image-dialog">
        <albi-dialog header="Errore dimensioni immagini etichetta" [visible]="Boolean(vm.loadedLabelImageFile)"
            (visibleChange)="removeLoadedLabelFile()">
            <div class="d-flex flex-column">
                <div class="text-sm font-regular">L'immagine caricata non rispetta le dimensioni richieste. È necessario
                    ritagliarla prima di procedere.</div>
                <app-image-crop class="flex-1" [height]="640" [width]="480" [imageFile]="vm.loadedLabelImageFile"
                    (imageResult)="updateLabelImageResult($event)"></app-image-crop>
            </div>
            <ng-template #footerTemplate>
                <div class="d-flex j-end gap-24">
                    <albi-button label="Annulla" buttonVariant="outlined"
                        (onClick)="removeLoadedLabelFile()"></albi-button>
                    <albi-button label="Applica" (onClick)="applyLabelCrop()"></albi-button>
                </div>
            </ng-template>
        </albi-dialog>

    </div>
    <!--  ERROR bottle size -->
    <div class="crop-image-dialog">
        <albi-dialog header="Errore dimensioni immagine bottiglia" [visible]="Boolean(vm.loadedBottleImageFile)"
            (visibleChange)="removeLoadedBottleFile()">
            <div class="d-flex flex-column">
                <div class="text-sm font-regular">L'immagine caricata non rispetta le dimensioni richieste. È necessario
                    ritagliarla prima di procedere.
                </div>
                <app-image-crop class="flex-1" [height]="960" [imageFile]="vm.loadedBottleImageFile"
                    (imageResult)="updateBottleImageResult($event)"></app-image-crop>
            </div>
            <ng-template #footerTemplate>
                <div class="d-flex j-end gap-24">
                    <albi-button label="Annulla" buttonVariant="outlined"
                        (onClick)="removeLoadedBottleFile()"></albi-button>
                    <albi-button label="Applica" (onClick)="applyBottleCrop()"></albi-button>
                </div>
            </ng-template>
        </albi-dialog>

    </div>
</ng-container>