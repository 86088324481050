import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DEFAULT_WINE_CONTAINER_CAPACITY, DEFAULT_WINE_GLASS_CAPACITY } from "src/variables/wine_quantities.variables";


@Component({
    selector: 'glass-counter',
    templateUrl: `./glass-counter.component.html`,
    styleUrls: ['./glass-counter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class GlassCounterComponent implements OnInit {

    @Input() quantityLeft: number;
    @Input() glassQuantity: number = DEFAULT_WINE_GLASS_CAPACITY;
    @Input() maxQuantity: number = DEFAULT_WINE_CONTAINER_CAPACITY;

    public glassess$ = new BehaviorSubject<{ glassType: string }[]>(null);

    constructor(private _elementRef: ElementRef) {

    }
    ngOnInit(): void {
        const glassessArr: { glassType: string }[] = [];
        for (let i = 0; i < (this.maxQuantity / this.glassQuantity); i++) {
            switch (true) {
                case (this.quantityLeft >= ((i + 1) * this.glassQuantity)):
                    glassessArr.push({ glassType: 'full' });
                    break;
                case (this.quantityLeft >= ((i * this.glassQuantity) + (this.glassQuantity / 2))):
                    glassessArr.push({ glassType: 'half' });
                    break;
                default:
                    glassessArr.push({ glassType: 'empty' });
            }
        }
        this.glassess$.next(glassessArr);
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('glass-counter');
    }
}