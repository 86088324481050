import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'objectKeysPipe',
    standalone: false
})
export class ObjectKeysPipe implements PipeTransform {
    transform(value: any, ...args: any[]): string[] {
        return value ? Object.keys(value) : [];
    }
}