export const SHARED_DICTIONARY = {
    boolean: {
        true: 'shared.boolean.true',
        false: 'shared.boolean.false',
        yes: 'shared.boolean.yes',
        no: 'shared.boolean.no',
    },
    status: {
        all: 'shared.status.all',
        pending: 'shared.status.pending',
        active: 'shared.status.active',
        inactive: 'shared.status.inactive'
    },
    speed_dial: {
        export: 'shared.speed_dial.export',
        deduplicate: 'shared.speed_dial.deduplicate',
        delete: 'shared.speed_dial.delete',
    },
    network_status: {
        online: 'shared.network_status.online',
        offline: 'shared.network_status.offline',
    },
    records: {
        updated_by_label: 'shared.records.updated_by_label',
        created_by_label: 'shared.records.created_by_label',
    }
}
