import { Routes } from '@angular/router';
import { AddressFormComponentComponent } from '@dashboard-components/address-form-component/address-form-component.component';
import { AppPageLayoutComponent } from '@dashboard-components/app-page-layout/app-page-layout.component';
import { CompanyCreationFormComponent } from '@dashboard-components/company-creation-form/company-creation-form.component';
import { GlassCounterComponent } from '@dashboard-components/glass-counter/glass-counter.component';
import { ImageCropComponent } from '@dashboard-components/image-crop-component/image-crop.component';
import { PageTabsComponent } from '@dashboard-components/page-tabs-component/page-tabs.component';
import { VintageCreationFormComponent } from '@dashboard-components/vintage-creation-form/vintage-creation-form.component';
import { WineCreationFormComponent } from '@dashboard-components/wine-creation-form/wine-creation-form.component';
import { DashboardComponent } from 'src/dashboards/dashboard/dashboard.component';
import { CalculateQuantityPipe } from 'src/dashboards/pipes/calculateQuantityPipe';
import { DateFormatPipe } from 'src/dashboards/pipes/dateFormat.pipe';
import { DescriptionPipe } from 'src/dashboards/pipes/description.pipe';
import { GetFileFormatPipe } from 'src/dashboards/pipes/getFileFormat.pipe';
import { IncludedInArrayPipe } from 'src/dashboards/pipes/includedInArrayPipe';
import { ObjectKeysPipe } from 'src/dashboards/pipes/objectKeys.pipe';
import { ResolveChipsArrayPipe } from 'src/dashboards/pipes/resolveChipsArray.pipe';
import { ResolveCountryCodePipe } from 'src/dashboards/pipes/resolveCountryCode.pipe';
import { ResolveNestedObjectFieldPipe } from 'src/dashboards/pipes/resolveNestedObjectFieldPipe';
import { ResolveUserCoordinatesPipe } from 'src/dashboards/pipes/resolveUserCoordinates.pipe';
import { SliderValueFormatPipe } from 'src/dashboards/pipes/sliderFormat.pipe';
import { UserNameOrEmailPipe } from 'src/dashboards/pipes/userEmailOrName.pipe';
import { CalculateAgePipe } from 'src/dashboards/user/pipes/calculateAge.Pipe';
import { CalculateRatingsPercentagePipe } from 'src/dashboards/wine/pipes/calculateRatingsPercentage.pipe';
import { GetStatusLabelPipe } from 'src/dashboards/wine/pipes/getStatusLabel.pipe';
import { ImageStringBooleanPipe } from 'src/dashboards/wine/pipes/imageStringBoolean.pipe';
import { LocaleDescriptionPipe } from 'src/dashboards/wine/pipes/localeDescription.pipe';
import { ResolveFullnamePipe } from 'src/dashboards/wine/pipes/resolveFullname.pipe';
import { NotFoundComponent } from 'src/errros/not-found/not-found.component';
import { HomeComponent } from 'src/home/home.component';
import { AuthGuard } from 'src/login/guards/auth.guard';
import { LoginComponent } from 'src/login/login.component';
import { DestroyableSubscription } from 'src/utils/destroyable-subscription';

export const DECLARATIONS = [
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    NotFoundComponent,
];

export const COMPONENT_DECLARATIONS = [
    AppPageLayoutComponent,
]

export const EXPORT_DECLARATIONS = [
    DestroyableSubscription,
    DescriptionPipe,
    DateFormatPipe,
    UserNameOrEmailPipe,
    GetFileFormatPipe,
    GetStatusLabelPipe,
    CalculateAgePipe,
    ResolveCountryCodePipe,
    SliderValueFormatPipe,
    ObjectKeysPipe,
    ResolveFullnamePipe,
    LocaleDescriptionPipe,
    ImageStringBooleanPipe,
    ResolveNestedObjectFieldPipe,
    CalculateQuantityPipe,
    IncludedInArrayPipe,
    CalculateRatingsPercentagePipe,
    ResolveUserCoordinatesPipe,
    GlassCounterComponent,
    PageTabsComponent,
    WineCreationFormComponent,
    VintageCreationFormComponent,
    ResolveChipsArrayPipe,

    CompanyCreationFormComponent,
    AddressFormComponentComponent,
    ImageCropComponent,
];


export const ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'wines',
                loadChildren: () => import('./../dashboards/wine/modules/wine.module').then(m => m.WineModule)
            },
            {
                path: 'companies',
                loadChildren: () => import('../dashboards/company/company.module').then(m => m.CompanyModule)
            },
            {
                path: 'document-pending',
                loadChildren: () => import('../dashboards/document-verification/document-verification.module').then(m => m.DocumentVerificationModule)
            },
            {
                path: 'companies/create',
                loadChildren: () => import('../dashboards/company/company-create/company-create.module').then(m => m.CompanyCreateModule)
            },
            {
                path: 'companies/:id',
                loadChildren: () => import('../dashboards/company/company-detail/modules/company-detail.module').then(m => m.CompanyDetailModule)
            },
            {
                path: 'users',
                loadChildren: () => import('../dashboards/user/user.module').then(m => m.UserModule),
            },
            {
                path: 'users/:id',
                loadChildren: () => import('../dashboards/user/user-detail/modules/user-detail.module').then(m => m.UserDetailModule),
            },
            {
                path: 'dispensers',
                loadChildren: () => import('../dashboards/dispenser/dispenser.module').then(m => m.DispenserModule)
            },
            {
                path: 'dispensers/:id',
                loadChildren: () => import('../dashboards/dispenser/dispenser-detail/modules/dispenser-detail.module').then(m => m.DispenserDetailModule)
            },
            /* {
                 path: 'tags',
                 loadChildren: () => import('../dashboards/tags/modules/tag.module').then(m => m.TagModule)
             },*/
            {
                path: 'qrcode',
                loadChildren: () => import('../dashboards/qrcode/qrcode.module').then(m => m.QrcodeModule)
            },
        ]
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: '/not-found'
    }
];
