
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/services/auth.service';
import { DASHBOARD_DICTIONARY } from 'src/translations/dictionaries/dashboard.dictionary';
import { DestroyableSubscription } from 'src/utils/destroyable-subscription';

@Component({
    selector: 'dashboard-component',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DashboardComponent extends DestroyableSubscription implements OnInit, AfterViewInit {

    dashboardDictionary = DASHBOARD_DICTIONARY;

    constructor(private _router: Router, private _elementRef: ElementRef, private _translate: TranslateService, private _authService: AuthService) {
        super()
    }

    ngOnInit(): void {
        if (this._router.routerState.snapshot.url === '/dashboard') {
            this._router.navigate(['/dashboard/wines/vintages']);
        }
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('dashboard-container');
    }


    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public logout() {
        this._authService.logout();
    }
}